.label {
	height: 100%;
	line-height: normal;
	padding-left: 5px;
}

.label-large {
	transform: scale(1) translate(0, -10px);
}

.error {
	color: red !important;
}

.error-field {
	border-color: red !important;
}
