/* css reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* fonts */

@font-face {
  font-family: LatoLight;
  src: url(./fonts/Lato-Light.eot);
  src: url(./fonts/Lato-Light.eot?#iefix) format('embedded-opentype'),
    url(./fonts/Lato-Light.woff) format('woff'), url(./fonts/Lato-Light.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Regular.eot);
  src: url(./fonts/Lato-Regular.eot?#iefix) format('embedded-opentype'),
    url(./fonts/Lato-Regular.woff) format('woff'), url(./fonts/Lato-Regular.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: LatoThin;
  src: url(./fonts/Lato-Thin.eot);
  src: url(./fonts/Lato-Thin.eot?#iefix) format('embedded-opentype'),
    url(./fonts/Lato-Thin.woff) format('woff'), url(./fonts/Lato-Thin.ttf) format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: Roboto;
  src: url(./fonts/Heebo-Regular.ttf);
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: RobotoLight;
  src: url(./fonts/Heebo-Light.ttf);
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: RobotoMedium;
  src: url(./fonts/Heebo-Medium.ttf);
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: RobotoBold;
  src: url(./fonts/Heebo-Bold.ttf);
  text-rendering: optimizeLegibility;
}

html,
body {
  font-family: LatoLight, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  background: linear-gradient(0deg, #1b242c 10%, #3f525b 90%);
  overflow: hidden;
  width: 100vw;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#root,
.App {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}



::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #efeff2;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4.5px;
  background-color: #6d7085;
}

.gm-style-iw {
  /* height: 300px;
  width: 270px !important;
  min-width: 270px;
  max-width: 270px; */
  /* min-height: 200px !important;
  max-height: 300px !important;
  min-width: 350px !important;
  max-width: 350px !important; */
  padding: 0 !important;
  /* box-shadow: none !important; */
  /* border: none !important; */
  border-radius: 4px !important;
  /* border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; */
}

.gm-style-iw-d {
  /* min-height: 100px !important;
  max-height: 280px !important;
  min-width: 300px !important; */
  overflow: auto !important;
}

/* .gm-style-iw-c {
  min-height: 100px !important;
  max-height: 280px !important;
  min-width: 340px !important;
} */

.gm-style-iw>button {
  color: yellow !important;
}

.gm-bundled-control.gm-bundled-control-on-bottom>div>div {
  border-radius: 4px !important;
}

.gm-style-iw>button>img {
  width: 1.4rem !important;
  height: 1.4rem !important;
  margin: 10px -3px !important;
  filter: invert(1);
  color: white;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiDialog-paperWidthSm {
  max-width: 750px;
}

.rdrDateRangePickerWrapper * {
  font-family: Roboto;
}

.rdrStaticRange {
  min-width: 116px;
  text-transform: capitalize;
  text-align: center;
}

.rdrStaticRange span {
  text-align: center;
}

.rdrStaticRanges {
  display: flex;
  flex-flow: row wrap !important;
  justify-content: space-around;
  align-items: center;
  background: #efeff2 !important;
}

.rdrDefinedRangesWrapper {
  min-width: 100%;
}

.rdrDateRangePickerWrapper {
  display: flex;
  flex-flow: column nowrap;
  max-width: 500px;
}

.rdrDateDisplay {
  display: none !important;
}

.rdrStaticRange {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrStaticRanges {
  padding: 10px;
  background: #eff2f7;
}

.rdrInputRanges {
  display: none;
}

.rdrNextPrevButton {
  background: #efeff2 !important;
}

.rdrMonthName {
  text-align: center !important;
}

.MuiInputAdornment-filled.MuiInputAdornment-positionStart {
  margin-top: 0 !important;
}

.fastField {
  font-size: 16px;
  width: 140px;
  min-width: 140px;
  height: 36px;
  min-height: 36px;
  background-color: #fff;
  color: #545964 !important;
  border: solid 1px #d5d2d5;
  border-radius: 4px;
  margin-right: 15px;
  font-family: Roboto;
  padding: 10px;
}

.fastField100 {
  width: 100px;
  min-width: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

[class^='MuiSlider-mark']:nth-of-type(3) {
  background-color: #bfbfbf;
  height: 12;
  width: 2;
  margin-top: -6;
}

[class^='MuiSlider-mark']:nth-of-type(4) {
  transform: translateX(-15%);
  font-weight: 500;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.54);
}

[class^='MuiSlider-mark']:nth-of-type(43) {
  background-color: #bfbfbf;
  height: 12;
  width: 2;
  margin-top: -6;
}

[class^='MuiSlider-mark']:nth-of-type(44) {
  transform: translateX(-85%);
  font-weight: 500;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.54);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
}

/* google maps keep outlining focused markers.. and we can't capture it with any class, so let's remove all outlines.. let's hope this does not fuck anything up (fingers crossed) */
#alerts-map div {
  outline-width: 0px;
}

#alerts-map div ::after {
  background: rgb(245 245 247);
}

.AppMobile {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100vw;
  max-width: 100vw;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.react-datepicker__input-container input {
  height: 35px;
  border-radius: 5px;
  border-width: 1px;
  width: 100%;
}

/**/

.redMainLoader {
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redMainLoader:after {
  content: "";
  background-image: url("./redSpinner.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  position: absolute;
  margin: -6px;
  width: inherit;
  height: inherit;
  animation: redMainLoader-spin 1.1s linear infinite, 1 !important;
  -webkit-animation: redMainLoader-spin 1.1s linear infinite, 1 !important;
}

@keyframes redMainLoader-spin {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes redMainLoader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
