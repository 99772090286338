.button {
	cursor: pointer;
	line-height: unset;
}

.disabled {
	cursor: not-allowed;
	opacity: 0.7;
}

.error {
	border: 2px solid red;
	border-radius: 8px;
}
