.error {
	color: red;
}

.itemName {
	font-family: 'Roboto';
	font-size: 15px;
	font-weight: normal;
	color: #545964;
	margin-bottom: 13px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.itemName:disabled {
	cursor: not-allowed;
}

.smallCheckbox {
	margin-right: 15px;
	padding: 0;
}

.smallCheckbox span {
	width: 24px;
	height: 24px;
	border-radius: 3px;
	background-color: #fff;
}

.wrapperGrid {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.itemGrid {
	width: 50%;
}

.wrapperList {
	width: 100%;
}

.itemList {
	width: 100%;
}

.disabled {
	opacity: 0.7;
	user-select: none;
	cursor: not-allowed;
	pointer-events: none;
}
