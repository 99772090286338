.select {
	border: solid 1px #d5d2d5;
	border-radius: 6px;
	padding: 5px;
}

.error {
	color: red;
	border: 2px solid red;
}
