.container {
	min-height: 50px;
	padding-top: 10px;
}
.wrapper {
	border-radius: 6px;
	padding: 5px 10px;
	min-height: 50px;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}
.error {
	color: red;
}
.datePicker {
	cursor: pointer;
}
.label {
	margin-right: 51px;
}
.errorDatePicker {
	border-color: red;
	border-width: 2px;
	color: red;
}
