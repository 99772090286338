.label-checked {
	color: #4b1c46;
	font-weight: 500;
	font-family: 'RobotoMedium';
}

.disabled {
	opacity: 0.7;
	user-select: none;
	cursor: not-allowed;
	pointer-events: none;
}
